<template>
  <div class="bg-white overflow-hidden" @keydown.enter="login">
    <div class="text-37px font-bold text-center mt-23px">
      欢迎使用始智AI平台
    </div>
    <el-tabs v-model="loginType">
      <el-tab-pane label="密码登录" name="password"></el-tab-pane>
      <el-tab-pane label="短信登录" name="shortmessage"></el-tab-pane>
    </el-tabs>
    <div class="px-56px">
      <!-- 密码登录 -->
      <div v-if="loginType === 'password'">
        <el-input placeholder="用户名/邮箱/手机号" v-model="username" >
          <img slot="prefix" class="login-icon" src="@/assets/images/login/user.png" />
        </el-input>
        <el-input class="mt-21px" placeholder="请输入密码" v-model="password" type="password" show-password>
          <img slot="prefix" class="login-icon" src="@/assets/images/login/password.png" />
        </el-input>
      </div>
      <!-- 短信登录 -->
      <div v-else>
        <el-input placeholder="请输入手机号" v-model="telphone">
          <img slot="prefix" class="login-icon" src="@/assets/images/login/user.png" />
        </el-input>
        <span v-if="notValidPhoneNumber" style="color: red; display: block">
          请输入正确的电话号码
        </span>
        <div class="flex mt-21px">
          <el-input placeholder="输入动态码" v-model="verificationCode" type="password">
            <img slot="prefix" class="login-icon" src="@/assets/images/login/password.png" />
          </el-input>
          <get-verification-code-btn :telphone="telphone" @status="sentCode"
            class="ml-16px"></get-verification-code-btn>
        </div>
      </div>

      <!-- 隐私政策 -->
      <div class="flex text-16px mt-15px">
        <div class="flex-1 text-grey">
          <el-checkbox v-model="agreeProtocol"></el-checkbox>
          <span class="ml-9px cursor-pointer" @click="agreeFn">阅读并同意</span>
          <span class="text-pri cursor-pointer" @click="visible = true">《用户协议与隐私政策》</span>
        </div>
        <p v-if="loginType === 'password'" class="cursor-pointer" @click="forgetPassword">
          忘记密码
        </p>
      </div>

      <el-button class="w-full mt-47px login-btn" type="primary" @click="login" :loading="loading"
        :disabled="loginDisabled" data-umami-event="登录">登录</el-button>
      <div class="text-16px text-grey text-center mt-48px">
        如果没有账号，请先
        <span class="text-pri cursor-pointer" @click="register" data-umami-event="注册">注册</span>
      </div>
    </div>
    <!-- 隐私协议弹层 -->
    <el-dialog :visible.sync="visible" width="8.3rem" append-to-body>
      <Protocol />
    </el-dialog>
  </div>
</template>

<script>
import Protocol from './protocol.vue'
import getVerificationCodeBtn from '@/components/get-verification-code-btn.vue'
import { valiMobilePhone } from '@/utils/validate'

export default {
  components: { Protocol, getVerificationCodeBtn },
  data() {
    return {
      loginType: 'password',
      username: '',
      password: '',
      telphone: '',
      verificationCode: '',
      agreeProtocol: false,
      visible: false,
      loading: false,
      codeSent: false
    }
  },
  computed: {
    // 手机号码检查
    notValidPhoneNumber() {
      const { status, msg } = valiMobilePhone(this.telphone)
      return !status
    },
    // 登录按钮禁用
    loginDisabled() {
      if (this.loginType === 'password') {
        return !this.username || !this.password
      } else if (this.loginType === 'shortmessage') {
        return !this.telphone || !this.verificationCode || !this.codeSent
      }
    }
  },
  created() { },
  mounted() { },
  methods: {
    sentCode() {
      this.codeSent = true;
    },
    register() {
      let datainfo = {
        click_event:'registerevent',
        click_event_data:''
      }
      this.$api.project.reportUserClickEvent(datainfo).then(res => {  }) 
      this.$emit('register')
    },
    forgetPassword() {
      this.$parent.$parent.visible.login = false
      this.$router.push('/forget-password')
    },
    agreeFn() {
      this.agreeProtocol = !this.agreeProtocol
    },
    // 登录
    login() {
      if (!this.agreeProtocol) {
        this.$message.warning('请先阅读并同意相关协议')
        return
      }
      this.loading = true
      let data = {}
      if (this.loginType === 'password') {
        // 密码
        data = {
          username: this.username.trim(),
          password: this.password,
          way: 'normal'
        }
      } else {
        // 验证码
        data = {
          username: this.telphone,
          password: this.verificationCode,
          way: 'sms'
        }
      }
      this.$api.user
        .login(data)
        .then((res) => {
          if (res.message === 'Success') {
            const { token, userid, username } = res.data
            localStorage.setItem('token', token) // 保存token

            if (username) {
              // 已注册用户登录成功
              // 用户信息存储在本地
              this.$store.dispatch('system/setUserInfo', {
                userid,
                username
              })
              this.$message.success('登录成功')
              this.$emit('login')
            } else {
              // 未注册用户验证码验证成功
              this.$store.dispatch('system/setUserInfo', {
                userid
              })
              this.$store.dispatch('system/setUserBaseInfo', {
                userid,
                phone: this.telphone
              })
              this.$emit('needPerfectInfo')
            }
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep {
  .el-input {
    height: 53px;
  }

  .el-tabs__header {
    margin-top: 40px;
    margin-bottom: 30px;

    .el-tabs__nav {
      left: 50%;
      transform: translateX(-50%) !important;

      .el-tabs__item {
        height: 60px;
        line-height: 60px;
        padding: 0 20px;
        font-size: 21px;
      }
    }
  }
}

.login-icon {
  width: 19px;
  height: 19px;
}

.login-btn {
  height: 53px;
}

@media only screen and (max-width: 767px) {
  .login-btn {
    height: 106px;
  }

 
  .login-icon {
    width: 40px;
    height: 40px;
  }

  ::v-deep {
    .el-input {
      height: 106px;
    }

    .el-checkbox__inner {
      width: 50px;
      height: 50px;
    }

    .el-checkbox__inner::after {
      display: none;
    }
    .el-input--prefix .el-input__inner{
    padding-left: 80px;
  }
  }
}

</style>
