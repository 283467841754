import path from '@/api/path'
import service from '@/api/service'
// import renewModel from '@/views/model/deploy-model/renew-model.vue'

const { project } = path

export default {
  // 算力列表
  getResourceInfoList: (data) => {
    return service.get(project.getResourceInfoList, data)
  },
  // 模型列表
  queryprojectlist: (data) => {
    return service.post(project.queryprojectlist, data)
  },
  // 体验列表
  queryspacelist: (data) => {
    return service.post(project.spaceList, data)
  },
  //个人体验
  getPersonModelServings: (data) => {
    return service.post(project.getPersonModelServings, data)
  },
//个人收藏模型
getCollectedModelServings: (data) => {
    return service.post(project.getCollectedModelServings, data)
  },
  //统计模型访问次数
  modelServingAccessCount: (data) => {
    return service.post(project.modelServingAccessCount, data)
  },
  //创建API服务
  createModelServingAPI: (data) => {
    return service.post(project.createModelServingAPI, data)
  },
  // 体验列表
  querytopspacelist: (data) => {
    return service.post(project.spaceTopList, data)
  },
  getModelRelateModelServings: (data) => {
    return service.post(project.getModelRelateModelServings, data)
  },
  getModelImages: (data) => {
    return service.post(project.getModelImages, data)
  },
  getModelImagesapi: (data) => {
    return service.post(project.getModelImagesapi, data)
  },
  getModelServingChargings: (data) => {
    return service.post(project.getModelServingChargings, data)
  },
  getAccountChargeOrders: (data) => {
    return service.post(project.getAccountChargeOrders, data)
  },
  startupModelServing: (data) => {
    return service.post(project.startupModelServing, data)
  },
  stopModelServing: (data) => {
    return service.post(project.stopModelServing, data)
  },
  // 创建体验
  createModelServing: (data) => {
    return service.post(project.createModelServing, data)
  },
  //续费功能
  renewModelServing: (data) => {
    return service.post(project.renewModelServing, data)
  },
    //添加apitoken
    addMSAPIKey: (data) => {
      return service.post(project.addMSAPIKey, data)
    },
    //获取服务器时间戳
    getCurrentMSTimeStamp:data=>{
      return service.post(project.getCurrentMSTimeStamp,data)
    },
   //获取apitoken列表
   getMSAPIKeys: (data) => {
    return service.post(project.getMSAPIKeys, data)
  },
   //删除apitoken
   delMSAPIKey: (data) => {
    return service.post(project.delMSAPIKey, data)
  },
  //设置默认token
  setDefaultMSAPIKey: (data) => {
    return service.post(project.setDefaultMSAPIKey, data)
  },
  // 个人账户信息
  getAccountInfo: (data) => {
    return service.post(project.getAccountInfo, data)
  },
  uploadMSImage: (data) => {
    return service.post(project.uploadMSImage, data)
  },
  uploadMSChat: (data) => {
    return service.post(project.uploadMSChat, data)
  },
  //微信支付接口
  wxpayMent: (data) => {
    return service.post(project.wxpayMent, data)
  },
  //支付结果查询
  checkPay: (data) => {
    return service.get(project.checkPay+data)
  },
  //获取时间戳
  getCurrentMSTimeStamp: (data) => {
    return service.post(project.getCurrentMSTimeStamp, data)
  },
  //服务公开
  setPersonMSVisable:data=>{
    return service.post(project.setPersonMSVisable,data)
  },
  //启动失败的开发环境重试
  reInitModelDev:data=>{
    return service.post(project.reInitModelDev,data)
  },
  //获取个人模型服务订单
  getModelServingOrders:data=>{
    return service.post(project.getModelServingOrders,data)
  },
  // 模型服务点赞
  modelservingzan: (data) => {
    return service.post(project.modelservingzan, data)
  },
  // 更新模型名称
  updateModelName: (data) => {
    return service.post(project.updateModelName, data)
  },
  // 更新数据集名称
  updateDatasetName: (data) => {
    return service.post(project.updateDatasetName, data)
  },
  // 更新代码名称
  updateCodeName: (data) => {
    return service.post(project.updateCodeName, data)
  },
  // 用户侧设置项目是否公开
  updateProjectVisable: (data) => {
    return service.post(project.updateProjectVisable, data)
  },
  // 模型服务收藏
  modelservingcollect: (data) => {
    return service.post(project.modelservingcollect, data)
  },
  modelservingcancelzan: (data) => {
    return service.post(project.modelservingcancelzan, data)
  },
  modelservingcancelcollect: (data) => {
    return service.post(project.modelservingcancelcollect, data)
  },
  getModelServing: (data) => {
    return service.post(project.getModelServing, data)
  },
  //获取已申请内置服务端口
  verifyGpuIsEnough: (data) => {
  return service.post(project.verifyGpuIsEnough, data)
  },
  getxpuCharg: (data) => {
    return service.post(project.getxpuCharg, data)
  },
  modelservinghaszan: (data) => {
    return service.post(project.modelservinghaszan, data)
  },
  modelservinghascollect: (data) => {
    return service.post(project.modelservinghascollect, data)
  },
  /* 创建模型 */
  // 基础信息
  addModel: (data) => {
    return service.post(project.addModel, data)
  },
  
  // 设置属性
  setAttr: (data) => {
    return service.post(project.setAttr, data)
  },
  // 添加标签
  addlabel: (data) => {
    return service.post(project.addlabel, data)
  },
  // 关联模型数据集
  related: (data) => {
    return service.post(project.related, data)
  },
  // 取消关联模型数据集
  cancelrelated: (data) => {
    return service.post(project.cancelrelated, data)
  },
  // 删除项目
  delproject: (data) => {
    return service.post(project.delproject, data)
  },
  // 点赞
  projectzan: (data) => {
    return service.post(project.projectzan, data)
  },
  // 取消点赞
  projectcancelzan: (data) => {
    return service.post(project.projectcancelzan, data)
  },
  // 收藏
  projectcollect: (data) => {
    return service.post(project.projectcollect, data)
  },
  // 取消收藏
  projectcancelcollect: (data) => {
    return service.post(project.projectcancelcollect, data)
  },
  /* 模型详情 */
  // 查询项目详情
  queryprojectinfo: (data) => {
    return service.post(project.queryprojectinfo, data)
  },
  // 查询项目关联列表
  relatelist: (data) => {
    return service.post(project.relatelist, data)
  },
  // 查询项目分支列表
  branchlist: (data) => {
    return service.post(project.branchlist, data)
  },
  // 文件提交commit记录
  commithistory: (data) => {
    return service.post(project.commithistory, data)
  },
  // 展示文件
  listfiles: (data) => {
    return service.post(project.listfiles, data)
  },
  // 创建问题
  createissue: (data) => {
    return service.post(project.createissue, data)
  },
  // 问题列表
  issuelist: (data) => {
    return service.post(project.issuelist, data)
  },
  // 创建讨论
  creatediscuss: (data) => {
    return service.post(project.creatediscuss, data)
  },
  // 讨论列表
  discusslist: (data) => {
    return service.post(project.discusslist, data)
  },
  // 删除讨论
  removediscuss: (data) => {
    return service.post(project.removediscuss, data)
  },
  // 增加分支
  addbranch: (data) => {
    return service.post(project.addbranch, data)
  },
  // 增加分支标签
  addtag: (data) => {
    return service.post(project.addtag, data)
  },
  // 查询项目网盘url
  getProjectWangpanurl: (data) => {
    return service.post(project.getProjectWangpanurl, data)
  },
  //动态获取xpu信心
  
  getXPUInfosByImageId: (data) => {
    return service.post(project.getXPUInfosByImageId, data)
  },
  //取消未生效的订单
  cancleModelServingOrder:data=>{
    return service.post(project.cancleModelServingOrder, data)
  },
  //创建开发环境
  createModelDevAPI:data=>{
    return service.post(project.createModelDevAPI, data)
  },
  //开发环境详情
  getModelDevDetail:data=>{
    return service.post(project.getModelDevDetail, data)
  },
  //获取开发环境订单
  getModelDevOrder:data=>{
    return service.post(project.getModelDevOrder, data)
  },
  //获取开发环境消费记录
  getModelDevChargings:data=>{
    return service.post(project.getModelDevChargings, data)
  },
   //获取实时日志
   getRealTimeLog:data=>{
    return service.post(project.getRealTimeLog, data)
  },
   //获取开发环境历史日志
   getHistoryTimeLog:data=>{
    return service.post(project.getHistoryTimeLog, data)
  },
   //停止开发环境
   stopModelDev:data=>{
    return service.post(project.stopModelDev, data)
  },
     //重新启动
     reStartModelDev:data=>{
      return service.post(project.reStartModelDev, data)
    },
  //停止开发环境
  setUserStorage:data=>{
    return service.post(project.setUserStorage, data)
  },
  //获取训练用镜像列表
  getModelImagesForTrain:data=>{
    let framework={
      framework_code:'324werffsfd3'//查询框架关联的开发环境镜像
    }
    return service.post(project.getModelImagesForTrain, framework)
  },
  //获取模型列表
  getmodelimagelist:data=>{
    return service.post(project.getModelImagesForTrain, data)
  },
   //获取配置列表
   getXPUInfosByImageIdForTrain:data=>{
    return service.post(project.getXPUInfosByImageIdForTrain, data)
  },
  //获取GPU利用率
  getGpuUtilization:data=>{
    return service.post(project.getGpuUtilization, data)
  },
  //获取CPU利用率
  getCpuUtilization:data=>{
    return service.post(project.getCpuUtilization, data)
  },
  //获取MeM利用率
  getMemUtilization:data=>{
    return service.post(project.getMemUtilization, data)
  },
  //开发环境home页列表
  getModelDevList:data=>{
    return service.post(project.getModelDevList, data)
  },
    //访问开发者大会页面统计
    reportUserClickWCDC:data=>{
      return service.post(project.reportUserClickWCDC, data)
    },
    //服务权限校验
    verifyMSAccess:data=>{
    return service.post(project.verifyMSAccess, data)
  },
    //模型代码数据集详情权限校验
    verifyProjAccess:data=>{
      return service.post(project.verifyProjAccess, data)
    },
//统计功能访问次数
  reportUserClickEvent:data=>{
    return service.post(project.reportUserClickEvent, data)
  },
//获取gitlab文件内容
getFileContent: (data) => {
  return service.post(project.getFileContent, data)
},
//校验用户是否有权限访问详情
getuserjurisdiction: (data) => {
  return service.post(project.getuserjurisdiction, data)
},
//申请内置服务端口
applyInnerServiceDomain: (data) => {
  return service.post(project.applyInnerServiceDomain, data)
},
//废弃内置服务端口
delInnerServiceDomain: (data) => {
  return service.post(project.delInnerServiceDomain, data)
},
//获取已申请内置服务端口
getInnerServiceDomains: (data) => {
  return service.post(project.getInnerServiceDomains, data)
},
//个人存储
//获取已申请内置服务端口
getClusterInfosByUserStorage: (data) => {
  return service.post(project.getClusterInfosByUserStorage, data)
},
//infos动态
//添加/更新动态
saveInfos: (data) => {
  return service.post(project.saveInfos, data)
},
//上传图片接口
uploadInfosContentImage: (data) => {
  return service.post(project.uploadInfosContentImage, data)
},
//获取动态详情
getInfosDetail: (data) => {
  return service.post(project.getInfosDetail, data)
},
//动态列表
getInfos: (data) => {
  return service.post(project.getInfos, data)
},
//用户动态列表
getUserInfos: (data) => {
  return service.post(project.getUserInfos, data)
},
//增加动态访问次数
setViewCount: (data) => {
  return service.post(project.setViewCount, data)
},
//获取标签
getInfosLabels: (data) => {
  return service.post(project.getInfosLabels, data)
},
//删除动态
delInfos: (data) => {
  return service.post(project.delInfos, data)
},
//设置是否公开
setInfosIsPublic: (data) => {
  return service.post(project.setInfosIsPublic, data)
},
//创建评论
createIssue: (data) => {
  return service.post(project.createIssue, data)
},
//创建回复
createDiscuss: (data) => {
  return service.post(project.createDiscuss, data)
},
//动态评论列表
getIssueList: (data) => {
  return service.post(project.getIssueList, data)
},
//评论的回复列表
getDiscussList: (data) => {
  return service.post(project.getDiscussList, data)
},
//增加点赞数
setLikeCount: (data) => {
  return service.post(project.setLikeCount, data)
},
//取消点赞数
cancleLikeCount: (data) => {
  return service.post(project.cancleLikeCount, data)
},
//删除自己的评论
delSelfIssue: (data) => {
  return service.post(project.delSelfIssue, data)
},
//删除自己的回复
delSelfDiscuss: (data) => {
  return service.post(project.delSelfDiscuss, data)
},
//添加收藏
addInfosCollect: (data) => {
  return service.post(project.addInfosCollect, data)
},
//添加收藏
getMyInfosCollect: (data) => {
  return service.post(project.getMyInfosCollect, data)
},
//批量下载图片
batchUploadInfosContentImage: (data) => {
  return service.post(project.batchUploadInfosContentImage, data)
},
//大赛选项获取
getCompetitionDics: (data) => {
  return service.post(project.getCompetitionDics, data)
},
//保存修改大赛报名信息
saveUserRegistration: (data) => {
  return service.post(project.saveUserRegistration, data)
},
//大赛报名详情
getUserRegistration: (data) => {
  return service.post(project.getUserRegistration, data)
},
//用户报名列表
getUserRegistrations: (data) => {
  return service.post(project.getUserRegistrations, data)
},
//取消报名
cancleUserRegistration: (data) => {
  return service.post(project.cancleUserRegistration, data)
},
//参赛组别和参赛赛区
getCompetitionAreaAndRegGroup: (data) => {
  return service.post(project.getCompetitionAreaAndRegGroup, data)
},
}