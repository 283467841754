<template>
  <div class="w-full flex flex-col" >
    <Header v-show="!$route.meta.noHeader"></Header>
    <!-- <div class="seizeseat" v-if="!$route.meta.noHeader"> </div> -->
    <div style="background-color: #f7f3fc" :class="!$route.meta.noHeader?'contmt':''">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Header from './header'
export default {
  components: { Header },
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>

.seizeseat{
  height: 0.3rem;
}
.contmt{
  margin-top: 0.2rem;
}
@media only screen and (max-width: 767px) {
  .seizeseat{
  height:1rem;
}
.contmt{
  margin-top: 0.6rem;
}
}

</style>
