<template>
    <div>
  <el-button
    plain
    class="text-pri"
    :disabled="disabled || !phoneValid"
    @click="getCode"
    :loading="loading"
    data-umami-event="手机号获取验证码(注册,登录)"
    :data-umami-event-telphone="telphone"
  >
    {{ btnText }}
  </el-button>

    <el-dialog :visible.sync="visible" width="3.6rem" append-to-body  title="人机验证">
     
      <div class="mb-0.2rem ">
        <img :src="basecode" alt="" style="width: 2rem;">
        <span class="cursor-pointer" @click="getverifycode">看不清？换一张</span>
      </div>
      <el-input style="width: 2rem; height: 0.2rem;" class="mr-0.1rem" placeholder="请输入正确的验证码" v-model="Verificationcode"></el-input>
      <el-button @click="getCode">验证</el-button>
  </el-dialog>
  </div>

</template>

<script>
import { valiMobilePhone } from '@/utils/validate'
import verification from '@/config/regularExpression'

export default {
  props: {
    telphone: {},
    checkPhone: {
      default: false
    }
  },
  data() {
    return {
      visible:false,
      basecode:'',
      codeid:'',
      disabled: false,
      phoneValid: false, // 手机号合法
      countdown: 60,
      interval: null,
      loading: false,
      isfirst:true,
      Verificationcode:''
    }
  },
  computed: {
    btnText() {
      if (this.disabled && this.phoneValid) {
        return this.countdown + '秒后，重新获取验证码'
      } else {
        return '获取动态码'
      }
    }
  },
  created() {
  },
  mounted() {},
  watch: {
    telphone: {
      handler(val) {
        this.phoneValid = false
        if (val) {
          const { status, message } = valiMobilePhone(val)
          this.phoneValid = status
        }
      },
      immediate: true
    }
  },
  beforeDestroy() {
    this.clearInterval()
  },
  methods: {
    getverifycode(){
      this.$api.user.createCaptcha({}) .then((res) => {
          this.codeid=res.data.Id
          this.basecode=res.data.Encode
      })
       
       
    },
    async getCode() {
      if (!verification.telphone.test(this.telphone)) {
        this.$message.error('手机号不合法')
        return
      }
      let phoneIsRegister = true
      if (this.checkPhone) {
        this.loading = true
        try {
          // 校验新手机号是否已注册
          const res = await this.$api.user.checkPhone(this.telphone)
          if (res.message === 'Success') {
            phoneIsRegister = false
          }
        } catch (error) {
        } finally {
          this.loading = false
        }
      } else {
        // 不需要校验
        phoneIsRegister = false
      }

      // 手机号未被注册
      if (!phoneIsRegister) {
        this.$api.user
          .sendCode({
            phone: this.telphone,
            id:this.codeid,
            answer:this.Verificationcode
          })
          .then((res) => {
            if(res.data==='show'){
              this.visible=true
              this.getverifycode()
            }else{
            if (res.message === 'Success') {
              this.$emit('status', 'success')
              //发送验证码成功状态初始化
              this.disabled = true
              this.visible=false
              this.codeid=''
              this.Verificationcode=''
              this.interval = setInterval(() => {
                this.countdown--
                if (this.countdown === 0) {
                  this.clearInterval()
                  this.resetBtnStatus()
                }
              }, 1000)
            } else {
              this.$emit('status', 'error')
              this.getverifycode()
              this.Verificationcode=''
            }
            }  
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    resetBtnStatus() {
      this.disabled = false
      this.countdown = 60
    },
    clearInterval() {
      clearInterval(this.interval)
      this.interval = null
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
