<template>
  <div class="user">
    <div class="flex items-center cursor-pointer" v-if="userInfo">
      <el-dropdown :show-timeout="100" @command="command">
        <div class="flex items-center">
          <a href="/my-message">
            <el-badge :value="unReadMessageNum.all" class="badge" :hidden="!unReadMessageNum.all">
              <avatar :src="userBaseInfo?.avatar" class="avatar" />
            </el-badge>
          </a>
          <span class="ml-15px user-name">{{ userInfo?.username || userBaseInfo?.phone }}</span>
          <i class="el-icon-caret-bottom ml-10px"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="center" class="command-list">用户中心</el-dropdown-item>
          <el-dropdown-item command="exit" class="command-list">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div v-else>
      <img @click="loginOrRegister" src="@/assets/images/login.png" class="login-btn cursor-pointer" />
    </div>

    <!-- 登录弹层 -->
    <el-dialog :visible.sync="visible.login" append-to-body :close-on-press-escape="false" :show-close="$route.meta.closelogin">
      <Login @register="goRegister" @login="login" @needPerfectInfo="needPerfectInfo" />
    </el-dialog>

    <!-- 完善信息弹层 -->
    <el-dialog :visible.sync="visible.perfectInfo" width="3.6rem" append-to-body>
      <PerfectInfo @save="perfectInfoSave" />
    </el-dialog>

    <!-- 注册弹层 -->
    <el-dialog :visible.sync="visible.register" width="3.6rem" append-to-body :close-on-press-escape="false" :show-close="$route.meta.closelogin">
      <Register @register="register" />
    </el-dialog>

    <!-- 注册完成弹层 -->
    <el-dialog :visible.sync="visible.finishRegister" width="8.33rem" append-to-body>
      <FinishRegister :username="registerUserName" :phone="registerPhone" @finish="finish" />
    </el-dialog>
  </div>
</template>

<script>
import Login from './login/index'
import Register from './login/register.vue'
import FinishRegister from './login/finish-register.vue'
import PerfectInfo from './login/perfect-info'
import { init } from '@/utils/getSelectOptions'
import avatar from '@/components/avatar.vue'
import cookie from '@/utils/cookie';
export default {
  components: { Login, Register, FinishRegister, PerfectInfo, avatar },
  data() {
    return {
      visible: {
        login: this.$store.state.system.showLoginModel,
        perfectInfo: this.$store.state.system.perfectInfoModel,
        register: false,
        finishRegister: false
      },
      registerUserName: '', // 注册时的用户名
      registerPhone: '', // 注册时的手机号
      interval: null
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.system.userInfo
    },
    userBaseInfo() {
      return this.$store.state.system.userBaseInfo
    },
    showLoginModel() {
      return this.$store.state.system.showLoginModel
    },
    perfectInfoModel() {
      return this.$store.state.system.perfectInfoModel
    },
    // 未读消息数
    unReadMessageNum() {
      return this.$store.state.system.unReadMessageNum
    }
  },
  watch: {
    'visible.login'(val) {
      this.$store.dispatch('system/toLogin', val)
    },
    'visible.perfectInfo'(val) {
      this.$store.dispatch('system/perfectInfo', val)
    },
    showLoginModel(val) {
      this.visible.login = val
    },
    perfectInfoModel(val) {
      this.visible.perfectInfo = val
    },
    userInfo(val) {
      if (val) {
        this.clearInterval()
        this.setUnReadGetMessageNumInterval()
      }
    }
  },
  beforeDestroy() {
    this.clearInterval()
  },
  created() {
    this.setUnReadGetMessageNumInterval()
  },
  methods: {
    closeModel() {
      for (const key in this.visible) {
        this.visible[key] = false
      }
    },
    login() {
      init() // 查询下拉选项信息
      // 查询并保存用户基本信息至vuex
      this.$api.user.saveUserBaseInfo()
      this.closeModel()
    },
    // 未注册用户验证码通过，需要完善信息
    needPerfectInfo() {
      this.$api.user.saveUserBaseInfo()
      this.closeModel()

      this.registerPhone = this.$store.state.system.userBaseInfo.phone
      this.visible.perfectInfo = true
    },
    // 打开登录弹层
    loginOrRegister() {
      this.closeModel()
      this.visible.login = true
    },
    // 打开注册弹层
    goRegister() {
      this.closeModel()
      this.visible.register = true
    },
    perfectInfoSave() {
      this.visible.perfectInfo = false
    },
    // 注册按钮事件
    register({ username, phone }) {
      this.closeModel()
      this.visible.finishRegister = true
      this.registerUserName = username
      this.registerPhone = phone
    },
    // 注册完成页完成按钮事件
    finish() {
      this.visible.finishRegister = false
      this.visible.login = true
    },
    command(command) {
      switch (command) {
        case 'center':
          this.userCenter()
          break
        case 'exit':
          this.exitLogin()
          break
        default:
          break
      }
    },
    userCenter() {
      this.$router.push('/individual-center')
    },
    // 退出登录
    exitLogin() {
      this.$confirm('确认退出登录？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message.success('用户已退出')
        this.$store.dispatch('system/setUserInfo', null)
        this.$store.dispatch('system/setUserBaseInfo', null)
        cookie.clearCookie('NoteCookie')
        localStorage.clear()
        const matchedPath = this.$route.matched.map((i) => i.path)
        if (matchedPath.includes('/individual-center')) {
          // 如果当前页面是个人中心，跳转到首页
          this.$router.replace('/')
        }
      })
    },
    setUnReadGetMessageNumInterval() {
      this.getUnReadMessageNum()
      const time = 60 * 1000 // 60s调用一次
      this.interval = setInterval(() => {
        this.getUnReadMessageNum()
      }, time)
    },
    clearInterval() {
      this.interval = null
      clearInterval(this.interval)
    },
    getUnReadMessageNum() {
      this.$store.dispatch('system/setUnReadMessageNum')
    }
  }
}
</script>

<style lang="scss" scoped>

.login-btn {
  width: 90px;
}

.avatar {
  width: 43px;
  height: 43px;
}
.user-name {
    max-width: 160px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.command-list {
  height: 40px;
  line-height: 40px;
}

::v-deep {
  .badge {
    margin-right: 15px;

    .el-badge__content {
      right: 10px;
      top: 5px;
      background: var(--primary-color);
    }
  }

  .el-dialog {
    width: 3.6rem;
  }
}

@media only screen and (max-width: 767px) {
  .login-btn {
    width: 240px;
  }

  .avatar {
    width: 86px;
    height: 86px;
  }
  .user-name {
    max-width: 300px;
  }

  .command-list {
    height: 80px;
    line-height: 80px;
  }

  ::v-deep {
    .el-dialog {
      width: 80%;
    }
  }

}

</style>
